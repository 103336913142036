<template>
  <div
    class="mb-20 p-3 mx-auto max-w-4xl md:p-6 lg:p-8 lg:min-h-screen lg:rounded lg:shadow-md"
  >
    <BannerFloating
      v-if="!store.is_open"
      class="fixed top-12 left-1/2 -translate-x-2/4"
    >
      <p class="m-auto text-sm leading-6 text-white">
        <strong class="font-semibold">{{ $t('the-store-is-closed') }}.</strong>
        {{ $t('it-opens-at') }} {{ store.open_time }}.
      </p>
    </BannerFloating>

    <div v-if="mainImage" class="w-full h-64 bg-gray-200 rounded-md lg:h-80">
      <img :src="mainImage" class="w-full h-full object-center object-cover" />
    </div>

    <div v-if="!mainImage" class="h-10"></div>

    <div
      class="info sticky top-12 mb-3 py-2 bg-white border-b-2 border-gray-200"
    >
      <div class="flex justify-between mb-3">
        <span class="text-lg font-bold">{{
          item && $translate(item.id, item.title).value
        }}</span>
        <span class="text-xl font-bold">
          {{ item && amountFromCents(item.price) }}
          {{ item && item.currency | currencify }}
        </span>
      </div>

      <div class="text-sm">
        {{ item && $translate(item.id, item.description).value2 }}
      </div>
    </div>

    <div
      class="mb-5 border-b-2 border-gray-200"
      v-for="(option, index) in item && item.options"
      :key="index"
    >
      <ProductOption
        :option="option"
        :item="item"
        @update:customizations="updateCustomizations"
      />
    </div>

    <div class="mb-5 border-b-2 border-gray-200">
      <div class="mb-2 text-lg font-bold">{{ $t('comments-title') }}</div>
      <textarea
        id="item-comments"
        type="text"
        v-model="comments"
        rows="3"
        class="border-0 px-3 py-3 placeholder-blueGray-300 w-full"
        :placeholder="$t('comments-placeholder')"
      ></textarea>
    </div>

    <PreFooter v-if="onlineOrderingAllowed">
      <plus-minus
        class="font-bold"
        :numberOfItems="multiples"
        @increase="increaseItems"
        @decrease="decreaseItems"
      />
      <LoadingButton
        :class="
          !store.is_open
            ? 'bg-gray-300 text-white cursor-not-allowed'
            : 'bg-gray-700 text-white hover:bg-gray-600'
        "
        :disabled="!store.is_open"
        @click="addToCart"
      >
        {{ $t('add-to-cart') }}
      </LoadingButton>
    </PreFooter>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton.vue'
import PlusMinus from '@/components/PlusMinus.vue'
import BannerFloating from '@/components/BannerFloating.vue'
import PreFooter from '@/components/PreFooter.vue'
import ProductOption from '@/components/ProductOption.vue'
import { ADD_TO_CART_REQUEST } from '../../store/actions/cart'
import { LOAD_CATALOG_REQUEST } from '../../store/actions/catalog'
import { FeatureService } from '../../utils/features'
import { amountFromCents, flattenCustomizations } from '../../utils/utils'

export default {
  name: 'CatalogItem',
  components: {
    LoadingButton,
    PlusMinus,
    BannerFloating,
    PreFooter,
    ProductOption,
  },

  metaInfo: {
    title: 'Catalog Item',
    titleTemplate: '%s | ClickOrder',
  },

  data() {
    return {
      checkedCustomizations: {},
      multiples: 1,
      comments: '',
      notAllowedToAddMore: {},
    }
  },

  created() {
    const storeId = this.$route.params.id
    this.$store.dispatch(LOAD_CATALOG_REQUEST, storeId)
  },

  computed: {
    onlineOrderingAllowed() {
      return FeatureService.isOnlineOrderingAllowed(
        this.$store.getters.getStore?.features
      )
    },
    store() {
      return this.$store.getters.getStore
    },
    item() {
      const id = this.$route.params.item_id
      return this.$store.getters.getItem(id)
    },
    mainImage() {
      const id = this.$route.params.item_id
      return this.$store.getters.getItem(id)?.images[0]
    },
  },

  methods: {
    amountFromCents,

    getDefaultSelections() {
      return this.item.options?.map(item => item?.customizations[0])
    },

    updateCustomizations(optionId, customizations) {
      this.checkedCustomizations[optionId] = customizations[optionId]
    },

    increaseItems() {
      this.multiples++
    },
    decreaseItems() {
      this.multiples--
    },

    addToCart() {
      for (let i = 0; i < this.multiples; i++) {
        let flatCustomizations = flattenCustomizations(
          this.checkedCustomizations
        )
        // TODO: https://github.com/ipinak/thesystem/issues/498
        if (flatCustomizations.length <= 0) {
          flatCustomizations = this.getDefaultSelections()
        }

        const orderItem = {
          item: {
            product: { id: this.item.id },
            customizations: flatCustomizations,
            comments: this.comments,
          },
          position: this.$store.getters.getPosition,
          cart_id: this.$store.getters.getCartId,
          store_id: this.$store.getters.getStoreId,
        }
        this.$store.dispatch(ADD_TO_CART_REQUEST, orderItem).then(() => {
          this.$router.push({
            path: `/store/${orderItem.store_id}/catalog`,
            query: {
              position: orderItem.position,
              area_short_id: this.$store.getters.getArea,
            },
          })
        })
      }
    },
  },
}
</script>
