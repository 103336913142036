<template>
  <div
    class="border-2 text-black active:bg-blueGray-600 py-2 lg:py-3 px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
  >
    <i class="fas fa-chair"></i>
    <router-link
      :to="{
        path: `/store/${storeId}/catalog`,
        query: { position: name },
      }"
    >
      {{ name }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Position',
  props: ['name', 'storeId', 'id'],
}
</script>
