<template>
  <div class="flex items-center">
    <router-link :to="detailsLink" class="flex-1">
      <span>{{ flatItem.product.title }}</span> -
      <span class="font-bold">
        {{ totalPrice }}
        <span> {{ flatItem.product.currency | currencify }}</span>
      </span>
      <span> x {{ multiples }}</span>
      <div class="flex flex-wrap">
        <p
          v-for="(c, index) in flatItem.customizations"
          :key="c.id"
          class="pr-1 text-xs text-gray-600"
        >
          {{ c.title
          }}{{ index !== flatItem.customizations.length - 1 ? ',' : '' }}
        </p>
      </div>
      <div v-if="flatItem.comments" class="text-xs text-gray-600">
        <span>{{ $t('comments-title') }}: </span>
        <span>{{ flatItem.comments }}</span>
      </div>
    </router-link>

    <plus-minus
      class="flex-none font-bold"
      :numberOfItems="multiples"
      @increase="addItem"
      @decrease="removeItem"
    ></plus-minus>
  </div>
</template>

<script>
import {
  ADD_TO_CART_REQUEST,
  REMOVE_CART_ITEM_REQUEST,
} from '../store/actions/cart'
import { amountFromCents } from '../utils/utils'
import PlusMinus from './PlusMinus.vue'

export default {
  name: 'CartItem',
  props: ['item', 'cartId'],

  components: {
    PlusMinus,
  },

  computed: {
    flatItem() {
      return this.item[1][0]
    },

    detailsLink() {
      return `/store/${this.$store.getters.getStoreId}/item/${this.flatItem.product.id}`
    },

    totalPrice() {
      let sum = 0
      this.flatItem?.customizations?.forEach(c => {
        sum += c.price
      })
      sum += this.flatItem.product.price
      return amountFromCents(sum)?.toLocaleString()
    },

    multiples() {
      return this.item[1].length
    },
  },

  methods: {
    removeItem() {
      const payload = { cartId: this.cartId, cartItemId: this.flatItem.id }
      this.$store.dispatch(REMOVE_CART_ITEM_REQUEST, payload)
    },

    addItem() {
      const orderItem = {
        item: {
          product: { ...this.flatItem.product },
          customizations: this.flatItem.customizations,
          comments: '', // NOTE: don't copy notes from the previous item
        },
        position: this.$store.getters.getPosition?.name,
        cart_id: this.$store.getters.getCartId,
        store_id: this.$store.getters.getStoreId,
      }
      this.$store.dispatch(ADD_TO_CART_REQUEST, orderItem)
    },
  },
}
</script>
