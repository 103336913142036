<template>
  <div class="relative min-h-screen p-3">
    <QrScanner ref="qrScanner" :shouldRedirect="true" />
    <div
      class="mx-auto max-w-4xl px-6 py-8 md:p-6 lg:p-8 shadow-2xl rounded bg-white"
    >
      <div class="flex items-center mb-3"></div>

      <div
        class="text-center font-semibold text-2xl p-2"
        v-if="!paymentSuccess"
      >
        {{ $t('try-again-by-scanning-the-qr') }}
      </div>

      <order-summary v-if="paymentSuccess" :order="order" />

      <PreFooter>
        <button
          class="m-auto px-4 py-2 md:px-6 md:py-3 text-sm font-bold uppercase rounded shadow border border-gray-300 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-white bg-gray-700 active:bg-blueGray-600 hover:bg-gray-600"
          @click="$refs.qrScanner.openCamera()"
        >
          {{ $t('re-scan-to-make-a-new-order') }}
        </button>
      </PreFooter>
    </div>
  </div>
</template>

<script>
import OrderSummary from '@/components/OrderSummary.vue'
import PreFooter from '@/components/PreFooter'
import QrScanner from '@/components/QrScanner.vue'
import { PaymentRequest } from '../../utils/requests'
import { ORDER_PAYMENT_REQUEST } from '../../store/actions/order'

export default {
  name: 'cash-order-payment',

  components: {
    OrderSummary,
    QrScanner,
    PreFooter,
  },

  data() {
    return {
      paymentSuccess: true,
    }
  },

  created() {
    const payload = new PaymentRequest(
      this.$route.params.id,
      '',
      'cash',
      'success'
    )

    this.$store.dispatch(ORDER_PAYMENT_REQUEST, payload)
  },

  computed: {
    order() {
      return this.$store.getters.getOrder
    },
  },
}
</script>
