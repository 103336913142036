function isLightColor(color) {
  // Variables for red, green, blue values
  let r, g, b, hsp

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return true
  }
  return false
}

function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach(item => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

function groupByCartItems(items) {
  return groupBy(items, cartItemGroupSelector)
}

function cartItemGroupSelector(item) {
  if (item.customizations?.length > 0) {
    item.customizations?.sort((a, b) => a !== b)
    return `${item.product?.id}_${item.customizations
      ?.map(x => x.id)
      ?.join('_')}`
  }
  return item.product?.id
}

function calculateDiscount(card, redemption) {
  if (card !== null && redemption !== null && card.points > 0) {
    const rate = card.points / redemption.conversion_rate
    const discountInCents = rate * 100
    const pointsUsed = discountInCents
    return { discount: discountInCents, pointsUsed }
  }
  return { discount: 0, pointsUsed: 0 }
}

function isEmpty(obj) {
  return obj === null || obj === undefined || obj === ''
}

function flattenCustomizations(customizations) {
  const flatten = []
  for (let key in customizations) {
    for (let i = 0; i < customizations[key].length; i++) {
      flatten.push({
        id: customizations[key][i],
      })
    }
  }
  return flatten
}

function amountFromCents(amount) {
  return amount / 100
}

function isNullOrUndefined(value) {
  return !value || value === null || value === undefined || value === ''
    ? false
    : true
}

module.exports = {
  isLightColor,
  groupByCartItems,
  groupBy,
  calculateDiscount,
  isEmpty,
  flattenCustomizations,
  amountFromCents,
  isNullOrUndefined,
}
