<template>
  <div
    class="mt-8 mb-20 p-3 mx-auto max-w-4xl md:p-6 lg:p-8 lg:min-h-screen lg:rounded lg:shadow-md"
  >
    <QrScanner
      ref="qrScanner"
      @decoded="handleDecodedString"
      :shouldRedirect="false"
    />

    <TotalCost2
      :totalCost="totalCost"
      :numOfCartItems="numOfCartItems"
      :currency="currency"
      :position="position"
      :hasDiscount="hasDiscount"
      :discount="discount"
    />

    <EmptyCart :message="cartMessage" v-if="isCartEmpty" class="my-4" />

    <div v-if="!isCartEmpty">
      <h3 class="mb-4 text-xl font-bold">{{ $t('my-products') }}</h3>

      <cart-item
        :class="{ 'border-gray-100 py-2': true, 'border-t-2': index > 0 }"
        v-for="(item, index) in groupedCartItems"
        :key="item.id"
        :item="item"
        :cartId="cartId"
      />

      <div class="mb-6 border-solid border-2 border-light-blue-500"></div>
      <div class="mb-6">
        <label
          for="notesOnOrder"
          class="form-label inline-block mb-2 text-gray-700"
        >
          {{ $t('would-you-like-to-leave-a-comment') }}
        </label>
        <textarea
          class="resize-none form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-md transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="notesOnOrder"
          rows="3"
          :placeholder="$t('your-comment')"
          v-model="notes"
        ></textarea>
      </div>

      <modal v-if="showModal" @close="showModal = false">
        <div
          slot="header"
          class="text-2xl font-semibold text-center p-1 sm:pb-5 sm:pt-2"
        >
          {{ $t('pay-with-card') }}
        </div>

        <div slot="body" class="mb-4">
          <stripe-element-payment
            v-if="stripeOptionsExists"
            ref="paymentRef"
            :pk="stripe.pk"
            :elements-options="stripeOptions"
            :confirm-params="confirmParams"
          />
        </div>

        <div slot="footer" class="flex">
          <l-button
            class="w-full bg-gray-700 text-white mr-2"
            v-if="stripeOptionsExists"
            @click="pay"
          >
            {{ $t('pay') }}
          </l-button>
          <l-button
            class="w-full border-1 border-gray-700 ml-2"
            @click="cancelPayment"
          >
            {{ $t('cancel') }}
          </l-button>
        </div>
      </modal>

      <div v-if="loyaltyAllowed">
        <h2 class="mb-4 font-bold text-lg">
          {{ $t('do-you-have-a-membership-card') }}
        </h2>
        <p class="mb-2">{{ $t('scan-your-membership-card-to-win') }}</p>
        <div v-if="decodedString" class="mb-2 text-gray-700">
          <div>{{ $t('your-code') }}: {{ decodedString }}</div>
          <div>{{ $t('your-points') }}: {{ card.points || 0 }}</div>
        </div>
        <button
          class="m-auto mb-6 px-3 py-2 text-sm font-bold uppercase rounded shadow border text-gray-700 hover:text-white hover:bg-gray-700 border-gray-600 active:bg-blueGray-100 hover:shadow-lg ease-linear transition-all duration-150 outline-none focus:outline-none"
          @click="$refs.qrScanner.openCamera()"
        >
          {{ $t('scan-your-card') }}
        </button>
      </div>

      <div class="mb-6">
        <h2 class="mb-4 font-bold text-lg">
          {{ $t('how-do-you-want-to-pay') }}
        </h2>

        <div class="p-0.5" v-if="cardAllowed">
          <label class="pr-1">{{ $t('card') }}</label>
          <input
            class="p-1"
            type="radio"
            value="card"
            name="paymentMethod"
            v-model="paymentMethod"
          />
          <span class="pl-2 text-sm">({{ $t('pay-with-card-now') }})</span>
        </div>

        <div class="p-0.5" v-if="cashAllowed">
          <label class="pr-1">{{ $t('cash') }}</label>
          <input
            class="pr-1"
            type="radio"
            value="cash"
            name="paymentMethod"
            v-model="paymentMethod"
          />
          <span class="pl-2 text-sm">
            ({{ $t('pay-with-cash-on-delivery') }})
          </span>
        </div>
      </div>

      <div class="mb-6" v-if="shouldShowCardUsage">
        <h2 class="mb-4 font-bold text-lg">
          {{ $t('do-you-want-to-use-your-points') }}
        </h2>

        <div class="p-0.5">
          <label class="pr-1">{{ $t('yes') }}</label>
          <input
            class="p-1"
            type="radio"
            value="true"
            name="useCardPoints"
            :v-bind:value="useCardPoints"
            v-on:change="toggleUseCardPoints"
          />

          <label class="pr-1">{{ $t('no') }}</label>
          <input
            class="pr-1"
            type="radio"
            value="false"
            name="useCardPoints"
            :v-bind:value="useCardPoints"
            v-on:change="toggleUseCardPoints"
          />
        </div>

        <div class="p-0.5" v-if="hasDiscount">
          {{ $t('discount') }}
          {{ discount.discount / 100 }}<span>€</span>
        </div>
      </div>

      <PreFooter>
        <TotalCost
          :totalCost="totalCost"
          :discount="discount"
          :hasDiscount="hasDiscount"
          :currency="currency"
          :position="position"
        >
        </TotalCost>

        <div class="p-1">
          <LoadingButton
            class="w-full bg-gray-700 text-white"
            ref="checkoutBtn"
            @click="checkout"
          >
            {{ $t('order-now') }}
          </LoadingButton>
        </div>
      </PreFooter>
    </div>

    <VivaWalletLogo class="text-sm" v-if="isVivaWallet" />

    <PricesIncludeVAT class="p-1 italic text-sm" />
  </div>
</template>

<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import CartItem from '@/components/CartItem.vue'
import LoadingButton from '@/components/LoadingButton.vue'
import Modal from '@/components/Modal.vue'
import PreFooter from '@/components/PreFooter.vue'
import QrScanner from '@/components/QrScanner.vue'
import TotalCost from '@/components/TotalCost.vue'
import TotalCost2 from '@/components/TotalCost2.vue'
import PricesIncludeVAT from '@/components/PricesIncludeVAT.vue'
import EmptyCart from '@/components/EmptyCart.vue'
import VivaWalletLogo from '@/components/VivaWalletLogo.vue'

import {
  GET_CART_REQUEST,
  ORDER_CHECKOUT_REQUEST,
  UPDATE_ORDER_REQUEST,
  SAVE_CARD,
  GET_CARD_REQUEST,
} from '../../store/actions'

import {
  groupByCartItems,
  calculateDiscount,
  isEmpty,
  amountFromCents,
} from '../../utils/utils'
import constants from '../../constants'
import { FeatureService } from '../../utils/features'
import { settings } from '../../settings'

export default {
  name: 'Cart',

  components: {
    CartItem,
    LoadingButton,
    'stripe-element-payment': StripeElementPayment,
    Modal,
    QrScanner,
    PreFooter,
    TotalCost,
    TotalCost2,
    VivaWalletLogo,
    PricesIncludeVAT,
    EmptyCart,
  },

  metaInfo: {
    title: 'Your Cart',
    titleTemplate: '%s | ClickOrder',
  },

  created() {
    const cartId = this.$route.params.id || this.$store.getters.getCartId
    this.$store.dispatch(GET_CART_REQUEST, cartId)
  },

  data() {
    return {
      useCardPoints: false,
      stripe: {
        pk: settings.STRIPE.pk,
      },
      viva: {
        checkout_web: settings.VIVA.checkout_web,
      },
      showModal: false,
      notes: '',
      paymentMethod: constants.CASH,
      isVivaWallet:
        this.$store.getters.getPaymentProvider === constants.VIVA_WALLET,
      decodedString: null,
    }
  },

  computed: {
    shouldShowCardUsage() {
      return !isEmpty(this.card?.id) && this.loyaltyAllowed
    },

    hasDiscount() {
      const card = this.$store.getters.getCard
      const redemptionRule = this.$store.getters.getStore?.redemption_rule

      return (card && redemptionRule && this.useCardPoints) || false
    },

    discount() {
      const card = this.$store.getters.getCard
      const redemptionRule = this.$store.getters.getStore?.redemption_rule

      return calculateDiscount(card, redemptionRule)
    },

    card() {
      return this.$store.getters.getCard
    },

    cardAllowed() {
      return FeatureService.isCardPaymentAllowed(
        this.$store.getters.getStore?.features
      )
    },

    cashAllowed() {
      return FeatureService.isCashPaymentAllowed(
        this.$store.getters.getStore?.features
      )
    },

    loyaltyAllowed() {
      return FeatureService.isLoyaltyAllowed(
        this.$store.getters.getStore?.features
      )
    },

    numOfCartItems() {
      return this.$store.getters.getCart?.items?.length || 0
    },

    isCartEmpty() {
      return this.$store.getters.isCartEmpty
    },

    currency() {
      const cart = this.$store.getters.getCart
      if (!cart?.items) return ''
      return cart.items.length > 0 ? cart.items[0].product.currency : ''
    },

    cartItems() {
      return this.$store.getters.getCart
    },

    groupedCartItems() {
      const clonedCart = JSON.parse(JSON.stringify(this.$store.getters.getCart))
      return groupByCartItems(clonedCart?.items)
    },

    cartId() {
      return this.$store.getters.getCartId || this.$store.getters.getCart?.id
    },

    stripeOptions() {
      return this.$store.getters.getElementOptions
    },

    stripeOptionsExists() {
      return this.$store.getters.getElementOptions?.clientSecret !== ''
    },

    confirmParams() {
      const orderId = this.$store.getters.getOrder.id

      return {
        return_url: `${settings.BASE_URI}/order/${orderId}/success`,
      }
    },

    totalCost() {
      const cart = this.$store.getters.getCart
      let sum = 0
      cart?.items?.forEach(item => {
        item.customizations?.forEach(c => {
          sum += c.price
        })
        sum += item.product.price
      })
      return amountFromCents(sum)
    },

    position() {
      return this.$store.getters.getPosition
    },

    cartMessage() {
      return this.$store.getters.getCartMessage === ''
        ? this.$i18n.t('your-cart-is-empty')
        : this.$store.getters.getCartMessage
    },
  },

  methods: {
    toggleUseCardPoints(event) {
      this.useCardPoints = event.target.value === 'true'
    },

    handleDecodedString(decodedString) {
      const storeId = this.$store.getters.getStore?.id
      this.decodedString = decodedString // assign decodedString to a data property
      this.$store.dispatch(SAVE_CARD, decodedString)
      this.$store.dispatch(GET_CARD_REQUEST, {
        card_id: decodedString,
        store_id: storeId,
      })
    },

    pay() {
      this.$refs.paymentRef.submit()
    },

    cancelPayment() {
      this.showModal = false
    },

    checkout() {
      const paymentProvider = this.$store.getters.getPaymentProvider
      const btn = this.$refs.checkoutBtn
      btn.startLoading()
      const data = {
        cart_id: this.$store.getters.getCartId,
        cart_items: this.$store.getters.getCart.items,
        notes: this.notes,
        payment_method: this.paymentMethod,
        card_id: this.$store.getters.getCard?.id,
        use_points: this.useCardPoints === 'true',
        area_short_id: this.$store.getters.getArea,
      }

      // Cash payment
      if (this.paymentMethod.toLowerCase() === constants.CASH) {
        this.$store
          .dispatch(ORDER_CHECKOUT_REQUEST, data)
          .then(() => {
            btn.stopLoading()
            const orderId = this.$store.getters.getOrder.id
            this.$router.push({
              path: `/order/${orderId}/cash`,
            })
          })
          .catch(() => {
            btn.stopLoading()
          })
        return
      }

      // Card payment
      if (!this.stripeOptionsExist && paymentProvider === constants.STRIPE) {
        this.$store
          .dispatch(ORDER_CHECKOUT_REQUEST, data)
          .then(() => {
            btn.stopLoading()
            this.showModal = true
          })
          .catch(() => {
            btn.stopLoading()
          })
      } else if (paymentProvider === constants.VIVA_WALLET) {
        this.$store
          .dispatch(ORDER_CHECKOUT_REQUEST, data)
          .then(() => {
            btn.stopLoading()
            const ref = this.$store.getters.getOrder.external_payment_id
            window.location = `${settings.VIVA.checkout_web}?ref=${ref}`
          })
          .catch(() => {
            btn.stopLoading()
          })
        // Go to the checkout page of viva
      } else {
        const order = {
          ...this.$store.getters.getOrder,
          items: this.$store.getters.getCart.items,
          notes: this.notes,
          payment_method: this.paymentMethod,
          card_id: this.$store.getters.getCard?.id,
        }

        this.$store
          .dispatch(UPDATE_ORDER_REQUEST, order)
          .then(() => {
            btn.stopLoading()
            this.showModal = true
          })
          .catch(() => {
            btn.stopLoading()
          })
      }
    },
  },
}
</script>
