<template>
  <div class="text-lg">
    <h1 class="pb-2 text-2xl font-semibold">
      {{ $t('thank-you-for-your-order') }}
    </h1>
    <h3 class="pb-10">
      {{ $t('your-order-is-underway') }}
    </h3>

    <!-- Divider -->
    <div class="border-t border-dashed border-gray-400 mb-4"></div>

    <div class="flex justify-between items-end mb-2 text-sm">
      <span class="mr-3 font-bold">{{ $t('order') }}</span>
      <span>{{ order.id && trimOrderId(order.id) }}</span>
    </div>
    <div class="flex justify-between items-end mb-2 text-sm">
      <div class="font-bold">{{ $t('created') }}</div>
      <div>{{ order.created_time | localdate }}</div>
    </div>
    <div class="flex justify-between items-end mb-2 text-sm">
      <div class="font-bold">{{ $t('state') }}</div>
      <div class="capitalize">{{ order.state }}</div>
    </div>
    <div class="flex justify-between items-end mb-3 text-sm">
      <div class="font-bold">{{ $t('position') }}</div>
      <div>{{ order.position.name }}</div>
    </div>

    <!-- Divider -->
    <div class="border-t border-dashed border-gray-400 mb-4"></div>

    <div class="flex justify-between items-end">
      <span>{{ $t('total-price') }}</span>
      <span class="text-xl font-semibold"
        >{{ amountFromCents(order.total)
        }}{{ order.currency | currencify }}</span
      >
    </div>
  </div>
</template>

<script>
import { amountFromCents } from '../utils/utils'

export default {
  name: 'OrderSummary',
  props: ['order'],

  methods: {
    trimOrderId: id => {
      return id.split('-')[0]
    },
    amountFromCents,
  },
}
</script>
