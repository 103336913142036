<template>
  <div>
    <hero :hero-image="store.hero_image" justify-content="center" size="small">
      <div class="p-2 md:p-5 flex lg:rounded-md lg:max-w-1/2 bg-white">
        <router-link
          :to="{ name: 'store', params: { id: store.id } }"
          class="flex justify-center h-20 mr-6"
        >
          <img :src="store.logo" :alt="store.name" />
        </router-link>
        <div>
          <h1 class="text-lg font-semibold">{{ store.name }}</h1>
          <div>{{ store.description }}</div>
          <div class="text-">
            <i class="fas fa-compass"></i>
            {{ store.address }}
          </div>

          <div>
            <i class="fas fa-clock"></i>
            {{ store.open_time }}-{{ store.close_time }}
          </div>
        </div>
      </div>
    </hero>
    <div
      class="mx-auto pt-6 pb-8 lg:pt-8 lg:pb-16 w-full relative"
      :class="showMenu ? 'fixed' : ''"
    >
      <BannerFloating
        v-if="!store.is_open"
        class="fixed top-12 left-1/2 -translate-x-2/4"
      >
        <p class="m-auto text-sm leading-6 text-white">
          <strong class="font-semibold"
            >{{ $t('the-store-is-closed') }}.</strong
          >
          {{ $t('it-opens-at') }} {{ store.open_time }}.
        </p>
      </BannerFloating>

      <div class="mx-auto max-w-4xl shadow-md rounded md:flex">
        <div
          class="absolute top-0 left-0 w-full h-full bg-slate-700 opacity-30 transition-opacity duration-1000 md:hidden"
          :class="showMenu ? 'fixed	' : 'hidden opacity-0 transition-opacity'"
          @click="toggleNav"
        />

        <aside
          class="fixed shrink-0 top-0 pt-32 p-8 h-full w-10/12 bg-white border-r border-gray-200 transition-transform ease-linear duration-100 overflow-y-auto z-10 md:block md:sticky md:top-11 md:p-6 md:w-56 md:h-screen lg:py-8"
          :class="
            showMenu
              ? ''
              : 'invisible -translate-x-full md:translate-x-0	md:visible'
          "
        >
          <CloseButton
            class="absolute top-16 right-4 p-0.5 rounded-full border-black md:hidden"
            :onClick="toggleNav"
          >
            <CloseIcon />
          </CloseButton>
          <h4 class="mb-7 font-semibold tracking-tight">
            {{ $t('categories') }}
          </h4>
          <CategoryList :onClick="toggleNav" />
        </aside>

        <main class="flex flex-col p-3 w-full md:p-6 lg:p-8 bg-white">
          <div class="mb-5 sticky lg:relative top-12 left-0 lg:top-0 w-full">
            <SearchInput v-model="searchTerm" @input="filterItems" />
          </div>

          <h2 class="mb-7 text-2xl font-extrabold tracking-tight">
            {{ $t('the-menu') }}
          </h2>
          <category
            v-for="item in categories"
            :key="item.id"
            :name="$translate(item.id, item.title).value"
            :id="item.id"
          >
            <catalog-item-thumbnail
              class="flex flex-col mb-3 pb-3 border-b"
              v-for="catItem in itemsByCategory(item.id)"
              :key="catItem.id"
              :item="catItem"
            />
          </category>
        </main>
      </div>

      <PreFooter>
        <div class="mr-2" v-if="onlineOrderingAllowed">
          <div class="font-bold text-base">
            {{ $t('items') }}: {{ numOfCartItems }}
          </div>
          <div class="text-sm" v-if="position">
            {{ position }}
          </div>
        </div>

        <div class="flex" :class="{ 'm-auto': !onlineOrderingAllowed }">
          <FilterButton
            :onClick="toggleNav"
            class="mr-2 bg-white text-sm font-bold rounded border border-gray-300 outline-none shadow ease-linear transition-all duration-150 active:bg-gray-100 hover:shadow-lg focus:outline-none md:hidden"
          >
            {{ $t('categories') }}
          </FilterButton>

          <router-link
            v-if="onlineOrderingAllowed"
            :class="
              isCartEmpty || !store.is_open
                ? 'cart bg-gray-300 cursor-not-allowed'
                : 'bg-gray-700 active:bg-blueGray-600 hover:bg-gray-600'
            "
            :is="isCartEmpty || !store.is_open ? 'span' : 'router-link'"
            :to="cartPath"
            class="px-4 py-2 md:px-6 md:py-3 text-sm font-bold uppercase rounded shadow border border-gray-300 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-white"
          >
            {{ $t('continue') }}
          </router-link>
        </div>
      </PreFooter>
    </div>
  </div>
</template>

<script>
import Category from '@/components/Category.vue'
import CategoryList from '@/components/CategoryList.vue'
import CatalogItemThumbnail from '@/components/CatalogItemThumbnail.vue'
import PreFooter from '@/components/PreFooter.vue'
import Hero from '@/components/Hero.vue'
import BannerFloating from '@/components/BannerFloating.vue'
import FilterButton from '@/components/Button.vue'
import CloseButton from '@/components/Button.vue'
import SearchInput from '@/components/SearchInput.vue'
import { SAVE_STORE_TABLE_INFO } from '@/store/actions/init'
import { LOAD_CATALOG_REQUEST } from '@/store/actions/catalog'
import { FeatureService } from '../../utils/features'
import CloseIcon from '../../assets/general/close.vue'

export default {
  data() {
    return {
      showMenu: false,
      searchTerm: '',
      filteredItems: [],
    }
  },
  name: 'Catalog',

  metaInfo: {
    title: 'Catalog',
    titleTemplate: '%s | ClickOrder',
  },

  components: {
    Category,
    CatalogItemThumbnail,
    CloseIcon,
    CategoryList,
    FilterButton,
    CloseButton,
    BannerFloating,
    PreFooter,
    Hero,
    SearchInput,
  },

  created() {
    const storeId = this.$route.params.id
    /// EXAMPLE: lang=en&position=Dwmatio2&area_short_id=a-DHJJD99
    const { position, area_short_id, lang } = this.$route.query

    this.$store.dispatch(LOAD_CATALOG_REQUEST, storeId)

    if (storeId === undefined || position === undefined) {
      return
    }

    this.$store.dispatch(SAVE_STORE_TABLE_INFO, {
      storeId,
      positionId: position,
      area_short_id,
      lang,
    })
  },

  computed: {
    computedItems() {
      const searchTerm = this.searchTerm.toLowerCase()
      if (!Array.isArray(this.catalog)) {
        return []
      }

      if (searchTerm) {
        return this.catalog.filter(item =>
          item.title.toLowerCase().includes(searchTerm)
        )
      }

      return this.catalog
    },

    onlineOrderingAllowed() {
      return FeatureService.isOnlineOrderingAllowed(
        this.$store.getters.getStore?.features
      )
    },

    cartPath() {
      return `/cart/${this.$store.getters.getCartId}`
    },

    catalog() {
      return this.$store.getters.getCatalog
    },

    categories() {
      const categories = this.$store.getters.getCategories
      if (!categories) {
        return []
      }

      return categories.filter(category => {
        const items = this.itemsByCategory(category.id)
        return items.length > 0
      })
    },

    store() {
      return this.$store.getters.getStore
    },

    isCartEmpty() {
      return this.$store.getters.isCartEmpty
    },

    numOfCartItems() {
      return this.$store.getters.getCart?.items?.length || 0
    },

    position() {
      return this.$store.getters.getPosition
    },
  },

  methods: {
    filterItems() {
      this.filteredItems = this.computedItems
    },

    itemsByCategory(id) {
      const items = this.$store.getters.getItemsByCategory(id)
      const searchTerm = this.searchTerm.toLowerCase()
      if (searchTerm) {
        return items.filter(item =>
          item.title.toLowerCase().includes(searchTerm)
        )
      }

      return items
    },

    toggleNav() {
      this.showMenu = !this.showMenu
    },
  },
  mounted() {
    this.filterItems()
  },
}
</script>
