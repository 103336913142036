import {
  CALL_SERVER_ERROR,
  CALL_SERVER_REQUEST,
  CALL_SERVER_RESPONSE,
} from '../actions/callings'
import { apiCall, api_routes } from '@/utils/api'

const state = {
  callings: [],
  status: '',
}

const getters = {
  getCallings: state => state.callings,
  hasCallings: state => state.callings.length > 0,
}

const actions = {
  [CALL_SERVER_REQUEST]: ({ commit }, data) => {
    if (
      state.callings.find(
        x => x.position === data.position && x.store_id === data.store_id
      )
    ) {
      return Promise.resolve('already called')
    }

    return new Promise((resolve, reject) => {
      commit(CALL_SERVER_REQUEST)
      apiCall({
        method: 'post',
        url: `${api_routes.callings}`,
        data,
      })
        .then(resp => {
          commit(CALL_SERVER_RESPONSE, data)
          resolve(resp)
        })
        .catch(err => {
          commit(CALL_SERVER_ERROR, err)
          reject(err)
        })
    })
  },
}

const mutations = {
  [CALL_SERVER_REQUEST]: state => {
    state.status = 'loading callings'
  },
  [CALL_SERVER_RESPONSE]: (state, data) => {
    state.status = 'loaded callings'
    state.callings.push(data)
  },

  [CALL_SERVER_ERROR]: state => {
    state.status = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
