<template>
  <div class="relative">
    <nav
      class="sticky z-20 top-0 flex flex-wrap items-center justify-between w-full px-3 md:px-6 bg-white h-12 shadow-sm navbar-expand-lg"
    >
      <back-button class="mr-4" />

      <LocaleSwitcher class="mr-auto" />

      <!-- floating button -->
      <button
        id="call-server"
        v-if="shouldShowServerCallButton"
        class="fixed left-4 bottom-20 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full shadow-lg sm:w-20 sm:py-3 sm:px-6 sm:text-xl"
        @click="callServer"
      >
        <i class="fas fa-concierge-bell"></i>
      </button>

      <cart-button
        class="ml-auto"
        :isCartAvailable="isCartAvailable"
        :cartId="cartId"
      />
    </nav>
    <router-view />
  </div>
</template>

<script>
import {
  SAVE_STORE_TABLE_INFO,
  FETCH_STORE_REQUEST,
} from '@/store/actions/init'
import { LIST_LOCALIZATIONS_REQUEST } from '@/store/actions/localizations'
import { CALL_SERVER_REQUEST } from '@/store/actions/callings'
import BackButton from '@/components/BackButton.vue'
import CartButton from '@/components/CartButton.vue'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import { FeatureService } from '@/utils/features'
import { isNullOrUndefined } from '@/utils/utils'

export default {
  name: 'Layout',

  components: {
    BackButton,
    CartButton,
    LocaleSwitcher,
  },

  beforeCreate() {
    const storeId = this.$route.params.id
    this.$store.dispatch(LIST_LOCALIZATIONS_REQUEST, storeId)
  },

  created() {
    const storeId = this.$route.params.id
    /// EXAMPLE: lang=en&position=Dwmatio2&area_short_id=a-DHJJD99
    const { position, area_short_id, lang } = this.$route.query

    if (storeId === undefined) {
      return
    }

    this.$store.dispatch(FETCH_STORE_REQUEST, storeId)
    if (position === undefined) {
      return
    }

    this.$store.dispatch(SAVE_STORE_TABLE_INFO, {
      storeId,
      positionId: position,
      area_short_id,
      lang,
    })
  },

  computed: {
    isCartAvailable() {
      if (this.$route.name === 'store') {
        return false
      }
      if (this.$route.name === 'order-success') {
        return false
      }
      return this.$route.name !== 'cart'
    },

    cartId() {
      return this.$store.getters.getCart?.id
    },

    shouldShowServerCallButton() {
      const hasPosition = isNullOrUndefined(this.$route.query.position)
      const isFeaureAllowed = FeatureService.isServerCallingAllowed(
        this.$store.getters.getStore?.features
      )
      return isFeaureAllowed && hasPosition
    },
  },

  methods: {
    goBack() {
      this.$router.back()
    },

    callServer() {
      this.$store.dispatch(CALL_SERVER_REQUEST, {
        store_id: this.$route.params.id,
        position: this.$route.query.position,
      })
    },
  },
}
</script>
