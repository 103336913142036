import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Checkbox, Radio } from 'vue-checkbox-radio'
import Button from '@/components/Button.vue'
import ActiveRouterLink from '@/components/ActiveRouterLink'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'

import '@fortawesome/fontawesome-free/css/all.min.css'
import './index.css'

import { currencify, toLocalDate } from './filters'
import i18n from './i18n'
import DynamicTranslator from './utils/translator'

Vue.config.productionTip = false

window.Event = new Vue()
Vue.component('checkbox', Checkbox)
Vue.component('radio', Radio)
Vue.component('Button', Button)
Vue.component('ActiveRouterLink', ActiveRouterLink)

Vue.filter('currencify', currencify)
Vue.filter('localdate', toLocalDate)
Vue.use(DynamicTranslator)
Vue.use(
  VueGtag,
  {
    config: { id: 'G-EM2VBEQG4G' },
  },
  router
)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
